<template>
	<div class="person_infor">
		<div class="login_top">
			<div class="img"> <img src="@/assets/login/flag.png"></div>
			<div class="login_title">{{$t('title.myProfile')}}</div>
		</div>
		<div class="infor">
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('my.headPortrait')}}
				</div>
				<div class="infor_item_right">
					<el-upload ref="upload" :show-file-list="false" :action="uploadImgUrl" list-type="picture-card"
						:limit="1" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
						<div class="upload_img" @mouseenter="mouseenterClick" @mouseleave="mouseleaveClick">
							<img :src="headImg" v-if="headImg">
							<span v-if="headImg&&isShowDeleteIcon" class="el-upload-action"
								@click.stop="handleRemove()">
								<i class="el-icon-delete"></i>
							</span>
							<img src="../../../assets/person/upload_img.png" v-if="!headImg" />
						</div>
						<div slot="tip" class="el-upload__tip">{{$t('uploadPhoto')}}</div>
					</el-upload>
				</div>
			</div>

			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('my.nickName')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="user_nickname" :placeholder="$t('my.inputNickName')"></el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('my.sex')}}
				</div>
				<div class="infor_item_right">
					<el-radio-group v-model="sex" fill="#E60012" text-color="#E60012">
						<el-radio :label="0">{{$t('my.private')}}</el-radio>
						<el-radio :label="1">{{$t('other.man')}}</el-radio>
						<el-radio :label="2">{{$t('other.women')}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('my.birth')}}
				</div>
				<div class="infor_item_right">
					<el-date-picker :picker-options="pickerDate" v-model="birthday" type="date"
						:title="$t('my.selectBirth')">
					</el-date-picker>
				</div>
			</div>
			<!-- 增加的 -->
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('wechatID')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="wechat" :placeholder="$t('inputWechatId')"></el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					WhatsApp
				</div>
				<div class="infor_item_right">
					<div class="whatsApp">
						<div class="dropdown">
							<el-dropdown @command="areaCodeClick">
								<span class="el-dropdown-link">
									{{whats_code}}<i class="el-icon-caret-bottom el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="(item,index) in areaCodeList" :key="index">
										{{item}}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="whatsapp_input">
							<input type="number" v-model="whatsApp" :placeholder="$t('inputWhatsApp')">
						</div>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('email')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="user_email" :placeholder="$t('login.inputEmail')"></el-input>
					</div>
				</div>
			</div>
			<!-- <div class="infor_item">
				<div class="infor_item_left">
					{{$t('email')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input type="text" clearable v-model="user_email" :placeholder="$t('login.inputEmail')">
						</el-input>
					</div>
				</div>
				<div class="code">
					<div class="code1" @click="getCode" v-if="!isGetCode">{{$t('login.getCode')}}</div>
					<div class="code2" v-else>{{$t('login.emailSendAgain')}}</div>
				</div>
			</div> -->
			<!-- <div class="infor_item">
				<div class="infor_item_left">
					{{$t('code')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="code" :placeholder="$t('inputEmailCode')"></el-input>
					</div>
				</div>
			</div> -->
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('mobile')}}
				</div>
				<div class="infor_item_right">
					<div class="whatsApp">
						<div class="dropdown">
							<el-dropdown @command="areaCodeClick2">
								<span class="el-dropdown-link">
									{{mobile_code}}<i class="el-icon-caret-bottom el-icon--right"></i>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item :command="item" v-for="(item,index) in areaCodeList" :key="index">
										{{item}}
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="whatsapp_input">
							<input type="number" v-model="mobile" :placeholder="$t('login.inputPhone')">
						</div>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('contacts')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="contacts" :placeholder="$t('inputContacts')"></el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('address')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<!-- @change="cascaderChangeClick($event,1)" -->
						<el-cascader v-model="areaVal" :options="countryList" clearable
							:placeholder="$t('other.selectCity')">
						</el-cascader>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('companyName')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="company_name" :placeholder="$t('inputCompanyName')" max="50"></el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('IDNumber')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable v-model="id_card" :placeholder="$t('inputIdNumber')" maxlength="18">
						</el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('aboutCompany')}}
				</div>
				<div class="infor_item_right">
					<div class="input">
						<el-input clearable style="width: 450px;" v-model="company_desc" type="textarea"
							:placeholder="$t('pleaseEnterCompanyProfile')" :autosize="{ minRows: 8, maxRows: 20}">
						</el-input>
					</div>
				</div>
			</div>
			<div class="infor_item">
				<div class="infor_item_left">
					{{$t('companyLogo')}}
				</div>
				<div class="infor_item_right">
					<el-upload ref="upload" :show-file-list="false" :action="uploadImgUrl" list-type="picture-card"
						:limit="1" :on-success="handleAvatarSuccess2" :before-upload="beforeAvatarUpload">
						<div class="upload_img" @mouseenter="mouseenterClick" @mouseleave="mouseleaveClick">
							<img :src="company_logo_url" v-if="company_logo_url">
							<span v-if="company_logo_url&&isShowDeleteIcon" class="el-upload-action"
								@click.stop="handleRemove2()">
								<i class="el-icon-delete"></i>
							</span>
							<img src="../../../assets/person/upload_img.png" v-if="!company_logo_url"  />
						</div>
						<div slot="tip" class="el-upload__tip">{{$t('uploadLogo')}}</div>
					</el-upload>
				</div>
			</div>

			<div class="info_item" @click="saveClick">
				<div class="save">{{$t('my.save')}}</div>
			</div>
		</div>
	</div>
	</div>
</template>

<script>
	import {
		uploadImgUrl
	} from '@/common/utils/config.js';


	export default {
		data() {
			return {
				uploadImgUrl: uploadImgUrl,

				userInfor: '',

				headImg: '', //头像
				pickerDate: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 8.64e6
					}
				}, //控制选择日期范围

				isFlag: false,

				isShowDeleteIcon: false,
				birthday: '',
				user_nickname: '',
				sex: '',

				wechat: '', //微信号
				whatsApp: '', //WhatsApp
				whats_code: '', //whatsApp区号
				contacts: '', //联系人
				province_id: '', //省id
				province_name: '', //省name
				addr_id: '', //市id
				addr_name: '', //市name
				area_id: '', //区id
				area_name: '', //区名称
				company_name: '', //公司名称
				id_card: '', //身份证号
				company_desc: '', //公司简介


				countryList: [], //国家列表
				areaCodeList: [], //区号列表
				areaCode: '',

				areaVal: '', //选中的地区

				user_email: '', //邮箱号
				code: '', //邮箱验证码
				isGetCode: false, //是否获取到验证码
				time: 60 * 10,
				timer: '',

				mobile: '', //手机号
				mobile_code: '', //手机号区号

				company_logo: '', //公司logo
				company_logo_url: '', //公司logourl
			}
		},
		mounted() {
			this.getCountry();
			this.init();
		},
		methods: {
			async init() {
				await this.getUserInfo();
				await this.getAreaCode();
			},

			// 获取验证码
			getCode() {
				if (!this.user_email) {
					this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
					return;
				}


				let data = {
					type: 7,
					is_test: 0,
					user_email: this.user_email
				}

				this.$http.getCode(data).then(res => {
					if (res.code == 1) {
						this.$message.success(this.$t('sendSuccess'));
						this.isGetCode = true;
						this.timer = setInterval(() => {
							if (this.time == 1) {
								clearInterval(this.timer);
								this.time = 60 * 10;
								this.isGetCode = false;
							}
							this.time--;
						}, 1000);
					} else {
						this.$message.error(res.msg);
					}
				}).catch((v) => {

				});
			},
			// 获取区号
			getAreaCode() {
				return new Promise((resolve, reject) => {
					this.$http.getAreaCode().then(res => {
						if (res.code == 1) {
							this.areaCodeList = res.data;
							if (!this.whats_code) {
								this.whats_code = res.data[0];
							}
							if (!this.mobile) {
								this.mobile_code = res.data[0];
							}
						} else {
							this.$message.error(res.data.msg);
						}
						resolve();
					}).catch((v) => {
						console.log(v);
					});
				})
			},
			// 区号点击
			areaCodeClick(command) {
				this.whats_code = command;
			},
			// 区号点击
			areaCodeClick2(command) {
				this.mobile_code = command;
			},
			// 获取国家
			getCountry() {
				this.$http.getCountry().then(res => {
					if (res.code == 1) {
						this.countryList = res.data;
						console.log(this.countryList);
						let newCountryList;
						newCountryList = this.countryList.map(item => {
							return {
								label: item.name,
								value: item.id,
								children: item.children.map(item2 => {
									return {
										label: item2.name,
										value: item2.id,
										children: item2.children.map(item3 => {
											return {
												label: item3.name,
												value: item3.id,
											}
										})
									}
								})
							}
						})
						this.countryList = JSON.parse(JSON.stringify(newCountryList));
					} else {
						this.$message.error(res.msg);
					}

				})
			},
			cascaderChangeClick(val, type) {
				console.log(val);
				console.log(type);
				switch (type * 1) {
					case 1:
						if (val.length > 0) {
							this.province_id = val[0]; //省id
							this.addr_id = val[1]; //市id
							this.area_id = val[2]; //分类id，
						} else {
							this.province_id = '';
							this.addr_id = '';
							this.area_id = '';
						}
						break;
				}

			},
			// 获取会员信息
			getUserInfo() {
				return new Promise((resolve, reject) => {
					this.$http.userInfo().then(res => {
						if (res.code == 1) {
							this.userInfor = res.data;
							this.birthday = this.userInfor.birthday;
							this.headImg = this.userInfor.head_img;
							this.user_nickname = this.userInfor.user_nickname;
							this.sex = this.userInfor.sex ? this.userInfor.sex : 0;
							this.wechat = this.userInfor.wechat;
							this.whats_code = this.userInfor.whats_code;
							this.whatsApp = this.userInfor.whats_app; //WhatsApp
							this.contacts = this.userInfor.contacts; //联系人
							this.province_id = this.userInfor.province_id; //省id
							this.province_name = this.userInfor.province_name; //省name
							this.addr_id = this.userInfor.addr_id; //市id
							this.addr_name = this.userInfor.addr_name; //市name
							this.area_id = this.userInfor.area_id; //区id
							this.area_name = this.userInfor.area_name; //区名称
							this.company_name = this.userInfor.company_name; //公司名称
							this.id_card = this.userInfor.id_card; //身份证号
							this.company_desc = this.userInfor.company_desc; //公司简介
							this.areaVal = [this.province_id, this.addr_id, this.area_id];

							this.user_email = this.userInfor.user_email;
							this.mobile = this.userInfor.mobile;
							this.mobile_code = this.userInfor.mobile_code;

							this.company_logo = this.userInfor.company_logo;
							this.company_logo_url = this.userInfor.company_logo_url;
						} else {
							this.$message.error(res.msg);
						}

						resolve();
					})
				})

			},
			//鼠标进入事件
			mouseenterClick() {
				if (this.headImg) {
					this.isShowDeleteIcon = true;
				}
			},
			// 鼠标离开事件
			mouseleaveClick() {
				if (this.headImg) {
					this.isShowDeleteIcon = false;
				}
			},
			handleRemove() {
				this.headImg = '';
				this.$refs.upload.clearFiles();
			},

			handleAvatarSuccess(res, file) {
				console.log(file);
				// this.$set(this.userInfor, 'head_img', file.response.data[0].path)
				this.headImg = file.response.data[0].path;
			},
			// 删除logo
			handleRemove2() {
				this.company_logo = '';
				this.company_logo_url = '';
				this.$refs.upload.clearFiles();
			},
			// 上传logo
			handleAvatarSuccess2(res, file) {
				console.log("哈哈哈");
				console.log(file);
				this.company_logo = file.response.data[0].id;
				this.company_logo_url = file.response.data[0].path;
			},

			// 上传之前
			beforeAvatarUpload(file) {
				console.log("jhhhh");
				const fileName = file.name
				const fileType = fileName.substring(fileName.lastIndexOf('.'));
				console.log("文件类型", fileType);

				if (
					fileType === '.jpg' ||
					fileType === '.png' ||
					fileType === '.jpeg' ||
					fileType === '.bmp' ||
					fileType === '.gif'
				) {

				} else {
					this.$message.error(this.$t('pleaseUploadTheCorrectImageType'))
					return false;
				}
			},
			// 保存
			saveClick() {
				if (!this.headImg) {
					this.$message.error(this.$t('uploadPhoto'));
					return;
				}
				// 邮箱验证
				if(this.user_email!=''){
				   let emailText=/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
				   if(!emailText.test(this.user_email)){
					   this.$message.error(this.$t('tips.validateUserEmail'))
					   return false;
				   }

				}
                if(this.wechat!=''){
					let regular=/^[a-zA-Z]([-_a-zA-Z0-9]{5,19})+$/;
					if(!regular.test(this.wechat)){
						this.$message.error(this.$t('tips.WeChatRegular'));
						return false
					}
				}
				// if (!this.user_email) {
				// 	this.$message.error(this.$t('tips.emailNumberCannotBeEmpty'))
				// 	return;
				// }

				// if (!this.code) {
				// 	this.$message.error(this.$t('tips.codeCannotBeEmpty'))
				// 	return;
				// }


				let data = {
					head_img: this.headImg,
					sex: this.sex,
					birthday: this.birthday,
					user_nickname: this.user_nickname,
					wechat: this.wechat, //微信号
					whats_code: this.whats_code,
					whats_app: this.whatsApp, //WhatsApp
					type: 7,
					user_email: this.user_email, //邮箱
					code: this.code,
					contacts: this.contacts, //联系人
					province_id: this.areaVal[0], //省id
					addr_id: this.areaVal[1], //市id
					area_id: this.areaVal[2], //区id
					company_name: this.company_name, //公司名称
					id_card: this.id_card, //Id，
					company_desc: this.company_desc, //公司简介
					mobile: this.mobile,
					mobile_code: this.mobile_code,
					company_logo: this.company_logo,
				}

				this.$http.edit_user_info(data).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						let newUserinfo = {
							...this.$store.state.userinfo,
							user_nickname: this.userInfor.user_nickname,
						}
						this.$store.commit('edituserinfo', newUserinfo);

					} else {
						this.$message.error(res.msg);
					}
				})
			},
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.img {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
			}
		}

		.login_title {
			margin-left: 8px;
			font-weight: bold;
			font-size: 18px;
		}
	}

	.infor {
		width: 560px;
		margin: 0 auto;
		margin-top: 24px;

		.infor_item {
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			position: relative;

			.infor_item_left {
				width: 80px;
				margin-right: 32px;
				text-align: left;
			}

			.infor_item_right {
				display: flex;

				.input {}

			}
		}
	}

	.info_item {
		padding-bottom: 36px;
	}

	.save {
		margin-top: 54px;
		width: 216px;
		text-align: center;
		height: 48px;
		line-height: 48px;
		background: #E60012;
		opacity: 1;
		border-radius: 24px;
		font-size: 16px;
		color: #FFFFFF;

		&:hover {
			cursor: pointer;
		}
	}

	.upload_img {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;

		img {
			border-radius: 50%;
			width: 110px;
			height: 110px;
		}
	}



	/* 选中后的字体颜色 */
	/deep/ .el-radio__input.is-checked+.el-radio__label {
		color: #E60012 !important;

	}

	/* 选中后小圆点的颜色 */
	/deep/ .el-radio__input.is-checked .el-radio__inner {
		background: #E60012 !important;
		border-color: #E60012 !important;
	}

	/deep/.el-radio {
		&:hover {
			border-color: #E60012 !important;
			color: #E60012 !important;

			//鼠标滑过时小圆点边框显示
			.el-radio__inner {
				border-color: #E60012;
			}
		}

		.el-radio__inner {

			// 鼠标滑过小圆点时的样式
			&:hover {
				border-color: #E60012;
			}
		}
	}

	/deep/ .el-upload--picture-card {
		width: 110px;
		height: 110px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		background-color: transparent;
	}

	/deep/ .el-upload__tip {
		color: #4177DD;
	}


	/deep/ .el-input {
		width: 450px;
	}

	.el-upload-action {
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		font-size: 0;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.el-upload-action:hover {
		font-size: 20px;
		background-color: #000;
		background-color: rgba(0, 0, 0, .3);
		border-radius: 50%;
	}

	.el-dropdown-menu {
		// width: 100px;
		max-height: 150px !important;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.whatsApp {
		display: flex;
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		color: #606266;
		font-size: inherit;
		width: 450px;
		height: 40px;
		line-height: 40px;
		border-radius: 4px;
		padding: 0 15px;
		cursor: pointer;

		.whatsapp_input {
			margin-left: 10px;

			input {
				width: 340px;
				color: #606266;
				border: none;
				outline: none;
			}
		}
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */
		color: #c8cbd1;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #c8cbd1;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #c8cbd1;
	}

	input::-ms-input-placeholder {
		/* Internet Explorer 10+ */
		color: #c8cbd1;
	}

	.code {
		position: absolute;
		right: 36px;
		color: #4177DD;
		font-size: 14px;

		.code1 {
			&:hover {
				cursor: pointer;
			}
		}
	}
</style>
